import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import AuthRepository from '@/api/repositories/Account/AuthRepository';
import {AuthState, RootState} from "@/misc/StoreStateInterface";
import AccountRepository from "@/api/repositories/Account/AccountRepository";
import Scope from "@/models/Scope";

export const AUTH_STORE_NAME = 'auth';

const initialState: AuthState = {
    token: undefined,
    refreshToken: undefined,
    isRefreshingToken: false,
    failedQueue: [],
    user: undefined,
    scopes: []
};

const store: AuthState = initialState;

export const enum AuthStoreActions {
    LOGIN = 'LOGIN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    GET_SCOPES = 'GET_SCOPES'
}

export const enum AuthStoreGetters {
    TOKEN = 'TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    IS_REFRESHING_TOKEN = 'IS_REFRESHING_TOKEN',
    SCOPES = 'SCOPES'
}

export const enum AuthStoreMutations {
    SAVE_TOKENS = 'SAVE_TOKENS',
    CLEAR_STORE = 'CLEAR_STORE',
    SET_SCOPES = 'SET_SCOPES'
}

const actions: ActionTree<AuthState, RootState> = {
    [AuthStoreActions.LOGIN]: async({commit}, payload: { username: string, password: string }): Promise<any> => {
        const tokens = await AuthRepository.login(payload);
        commit(AuthStoreMutations.SAVE_TOKENS, tokens);
        return tokens;
    },
    [AuthStoreActions.REFRESH_TOKEN]: async({commit}, token: string): Promise<{ access_token: string, refresh_token: string }> => {
        return await AuthRepository.refreshToken(token);
    },
    [AuthStoreActions.GET_SCOPES]: async({commit}): Promise<Scope[]> => {
        const scopesResult = await AccountRepository.getScopes();
        const scopes = Scope.parseFromArray(scopesResult.data) as Scope[];
        commit(AuthStoreMutations.SET_SCOPES, scopes);
        return scopes;
    },
};

const mutations: MutationTree<AuthState> = {
    [AuthStoreMutations.SAVE_TOKENS]: (state: AuthState, payload: { access_token: string, refresh_token: string }) => {
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
    },
    [AuthStoreMutations.SET_SCOPES]: (state: AuthState, scopes: Scope[]) => {
        state.scopes = scopes;
    },
    [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialState);
    },
};
const getters: GetterTree<AuthState, RootState> = {
    [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
    [AuthStoreGetters.REFRESH_TOKEN]: (state: AuthState) => state.refreshToken,
    [AuthStoreGetters.IS_REFRESHING_TOKEN]: (state: AuthState) => state.isRefreshingToken,
    [AuthStoreGetters.SCOPES]: (state: AuthState) => state.scopes
};

const authStore: Module<AuthState, RootState> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default authStore;

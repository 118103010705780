import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/styles/styles.scss';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import vuetify from '@/plugins/vuetify';
import 'leaflet/dist/leaflet.css';
import i18n from './i18n';
import store from "./stores";
import Toast from 'vue-toastification';
import CustomNotifications from "@/plugins/CustomNotification.plugin";
import "vue-toastification/dist/index.css";
import FormattingPlugin from "@/plugins/Formatting.plugin";
import ErrorHandlerPlugin from "@/plugins/ErrorHandler.plugin";
import ScopeValidation from "@/plugins/ScopeValidation.plugin";

// Vue.use(Vuelidate);
Vue.use(Vuex);
Vue.config.productionTip = false;

Vue.use(Toast, {});
Vue.use(CustomNotifications);
Vue.use(ErrorHandlerPlugin);
Vue.use(FormattingPlugin);
Vue.use(ScopeValidation);
Vue.filter('truncate', function(text:string, stop:any, clamp:any) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

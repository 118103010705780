import Parseable from '@/misc/Parseable';

export default class Gas extends Parseable {
    public id?:string;
    public radius?:number;
    public latitude?: number;
    public longitude?:number;
    public lastUpdated?:string;

    public static parseFromObject(object: Partial<Gas>): Gas {
        const gas = new Gas();
        Object.assign(gas, object);
        return gas;
    }

    public parseToObject(): Partial<Gas> {
        const tmp: any = {...this};
        return tmp;
    }
}

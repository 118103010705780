import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AxiosResponse} from "axios";
import {DisposalTypeEnum} from "@/enum/Disposaltype.enum";

export default class DisposalRepository {
    public static async displacement(payload: object): Promise<void> {
        return ENTITY_CLIENT.post('/api/disposal/admin/displacements', payload);
    }

    public static async loadDisplacements(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get('/api/disposal/admin/displacements');
    }

    public static statusDisplacements(payload: object): Promise<void> {
        return ENTITY_CLIENT.post('/api/disposal/admin/displacements/status', payload);
    }

    /**
     * uploads the calendar
     * @param payload
     */
    public static uploadPaperPlasticCalendar(payload: {file: any, sheetNumber: number, disposalType: DisposalTypeEnum}): Promise<void> {
        const bodyFormData = new FormData();
        bodyFormData.append('calendar', payload.file);
        bodyFormData.append('sheetNumber', payload.sheetNumber.toString());
        bodyFormData.append('disposalType', payload.disposalType.toString());

        return ENTITY_CLIENT.post(`/api/disposal/admin/calendar/upload/keys`,
            bodyFormData, {headers: {'Content-Type': "multipart/form-data"}});
    }

    /**
     * uploads the calendar
     * @param payload
     */
    public static uploadDisplacements(payload: {file: any, sheetNumber: number}): Promise<void> {
        const bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);
        bodyFormData.append('sheetNumber', payload.sheetNumber.toString());

        return ENTITY_CLIENT.post(`/api/disposal/admin/displacements/batch`,
            bodyFormData, {headers: {'Content-Type': "multipart/form-data"}});
    }

    /**
     * uploads the calendar
     * @param payload
     */
    public static uploadCalendar(payload: {file: any, sheetNumber: number}): Promise<void> {
        const bodyFormData = new FormData();
        bodyFormData.append('calendar', payload.file);
        // bodyFormData.append('validThroughYear', '2023');
        bodyFormData.append('sheetNumber', payload.sheetNumber.toString());

        return ENTITY_CLIENT.post(`/api/disposal/admin/calendar/upload`,
            bodyFormData, {headers: {'Content-Type': "multipart/form-data"}});
    }

    public static async importCSVFile(SheetNumber: string, StartingRowNumber: string, xlsxFile: File): Promise<any> {
        const bodyFormData = new FormData();
        bodyFormData.append('calendar', xlsxFile);
        bodyFormData.append("sheetNumber", SheetNumber);
        bodyFormData.append("startingRowNumber", StartingRowNumber);
        return await ENTITY_CLIENT.post(`/api/disposal/admin/calendar/upload`, bodyFormData,
            {
                headers: {'Content-Type': "multipart/form-data"}});
    }

    /**
     * deletes a list of displacements
     * @param ids
     */
    public static async deleteDisplacements(ids: string[]): Promise<void> {
        const payload = ids.join(',');
        return await ENTITY_CLIENT.delete(`/api/disposal/admin/displacements/${payload}`);
    }
}

import DisposalRepository from '@/api/repositories/DisposalRepository';
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import DisposalDisplacement from "@/models/DisposalDisplacement";
import {DisposalState, RootState} from "@/misc/StoreStateInterface";

export const DISPOSAL_STORE_NAME = 'disposal';

const initialState: DisposalState = {
    displacements: undefined
};

const store: DisposalState = initialState;

export const enum DisposalStoreActions {
    LOAD_DISPLACEMENTS = 'LOAD_DISPLACEMENTS',
    CREATE_DISPLACEMENTS = 'CREATE_DISPLACEMENTS',
    STATUS_DISPLACEMENT = 'STATUS_DISPLACEMENT'
}

export const enum DisposalStoreGetters {
    DISPLACEMENTS = 'DISPLACEMENTS',
}

export const enum DisposalStoreMutations {
    STORE_DISPLACEMENTS = 'STORE_DISPLACEMENTS',
    CLEAR_STORE = 'CLEAR_STORE'
}


const actions: ActionTree<DisposalState, RootState> = {
    [DisposalStoreActions.CREATE_DISPLACEMENTS]: async({commit}, payload: { disposalTypes: [], displacementDate: string, displacementDays: string, override: boolean, active: boolean, displacementReason: string, displacementComment: string }): Promise<any> => {
        const loginDataRaw = await DisposalRepository.displacement(payload);
        return loginDataRaw;
    },
    [DisposalStoreActions.LOAD_DISPLACEMENTS]: async({commit}): Promise<DisposalDisplacement[]> => {
        const disposalRaw = await DisposalRepository.loadDisplacements();
        const createdDisposal = DisposalDisplacement.parseFromArray(disposalRaw.data);
        commit(DisposalStoreMutations.STORE_DISPLACEMENTS, createdDisposal);
        return createdDisposal;
    },
    [DisposalStoreActions.STATUS_DISPLACEMENT]: async({commit}, payload: { statusList: { id: string, active: boolean }[] }): Promise<any> => {
        const statusDataRaw = await DisposalRepository.statusDisplacements(payload);
        return statusDataRaw;
    }
};

const mutations: MutationTree<DisposalState> = {
    [DisposalStoreMutations.STORE_DISPLACEMENTS]: (state: DisposalState, displacements: DisposalDisplacement[]) => {
        state.displacements = displacements;
    },
    [DisposalStoreMutations.CLEAR_STORE]: (state: DisposalState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialState);
    }
};

const getters: GetterTree<DisposalState, RootState> = {
    [DisposalStoreGetters.DISPLACEMENTS]: (state: DisposalState) => state.displacements
};

const disposalStore: Module<DisposalState, RootState> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default disposalStore;
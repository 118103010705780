import {AxiosResponse} from "axios";
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 *  Auth repository that provides authentication api methods
 */
export default class AccountRepository {
    public static async getScopes(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get('/api/account/scopes');
    }
}

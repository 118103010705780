import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState, SportState} from "@/misc/StoreStateInterface";
import Tenant from "@/models/Tenant";

const initialState: SportState = {
    selectedTenant: undefined
};

const store: SportState = initialState;

export const enum SportStoreActions {
}

export const enum SportStoreGetters {
    SELECTED_TENANT = 'SELECTED_TENANT',
}

export const enum SportStoreMutations {
    SET_SELECTED_TENANT = 'SET_SELECTED_TENANT',
    CLEAR_STORE = 'CLEAR_STORE'
}

const actions: ActionTree<SportState, RootState> = {
};

const mutations: MutationTree<SportState> = {
    [SportStoreMutations.SET_SELECTED_TENANT]: (state: SportState, tenant: Tenant) => {
        state.selectedTenant = tenant;
    },
    [SportStoreMutations.CLEAR_STORE]: (state: SportState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialState);
    },
};

const getters: GetterTree<SportState, RootState> = {
    [SportStoreGetters.SELECTED_TENANT]: (state: SportState) => state.selectedTenant
};

const sportStore: Module<SportState, RootState> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default sportStore;

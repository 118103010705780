import i18n from '@/i18n';
import Scope from "@/models/Scope";
import store from '@/stores/index';
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/stores/auth.store";
import {SupportedScope} from "@/enum/SupportedScopes";

/**
 * A small plugin to abstract and ease the invocation of vue-notification notifications.
 */

/**
 * Default options
 */
let defaultOptions = {};

const ScopeValidation = {
    install(Vue: any, options: any = {}) {
        // handle options
        defaultOptions = Object.assign(defaultOptions, options);

        const hasScope = (scopeList: SupportedScope[]): boolean => {
            const scopes = store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.SCOPES}`] as Scope[] | undefined;
            if(!scopes) return false;
            return scopes.some((s) => scopeList.find((sl) => sl.toString() === s.scope.toString()));
        };
        Vue.prototype.$hasScope = hasScope;
        Vue.hasScope = hasScope;
    }
};
export default ScopeValidation;

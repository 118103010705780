import {AUTH_CLIENT} from '@/api/misc/AuthClient';

/**
 *  Auth repository that provides authentication api methods
 */
export default class AuthRepository {
    public static async login(payload: object): Promise<{ access_token: string, refresh_token: string }> {
        const clientParameters = {
            client_id: 'evi-admin',
            client_secret: 'ZfHEOSp39MMdQP40ku1OrilNgmRT6sdL',
            grant_type: 'password'
        };

        const encodedPayload = new URLSearchParams({...payload, ...clientParameters}).toString();
        const response = await AUTH_CLIENT.post(process.env.VUE_APP_AUTH_URL, encodedPayload, {
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            },
        });
        return response.data;
    }

    public static async refreshToken(token: string): Promise<{ access_token: string, refresh_token: string }> {
        return AUTH_CLIENT.post(process.env.VUE_APP_AUTH_URL, {refresh_token: token});
    }
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import defaultThemeLight from './themes/defaultLight';
import defaultThemeDark from './themes/defaultDark';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default value
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: defaultThemeLight,
      dark: defaultThemeLight,
    },
  },
});

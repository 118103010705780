import Gas from "@/models/GasMap";
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AxiosResponse} from "axios";

export default class GasStationRepository {
    public static async loadGasStations() : Promise<AxiosResponse>{
        return ENTITY_CLIENT.get('/api/gas-stations/admin/configuration');
    }

    public static async updateGas(payload: object): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post('/api/gas-stations/admin/configuration', payload);
    }
}

import Parseable from '@/misc/Parseable';

export default class DisposalDisplacement extends  Parseable {
    public id?:string;
    public isActive?:boolean;
    public reason?:string;
    public comment?:string;
    public from?:string;
    public to?:string;
    public type?:[];

    public static parseFromObject(object: Partial<DisposalDisplacement>): DisposalDisplacement {
        const disposal = new DisposalDisplacement();
        Object.assign(disposal, object);
        return disposal;
    }

    public parseToObject(): Partial<DisposalDisplacement> {
        const tmp: any = {...this};
        return tmp;
    }
}



import UserRepository from '@/api/repositories/UserRepository';
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import User from "@/models/User";
import {RootState, UserState} from "@/misc/StoreStateInterface";
import ProfileRepository from "@/api/repositories/Account/ProfileRepository";
import AdminAccountsRepository from "@/api/repositories/Account/AdminAccountsRepository";

export const USER_STORE_NAME = 'user';

const initialState: UserState = {
    currentUser: undefined
};

const store: UserState = initialState;

export const enum UserStoreActions {
    GET_USER = 'GET_USER',
    GET_SCOPES = 'GET_SCOPES',
}

export const enum UserStoreGetters {
    USER = 'USER',
}

export const enum UserStoreMutations {
    STORE_USER = 'SET_COMPANY',
    CLEAR_STORE = 'CLEAR_STORE'
}

const actions: ActionTree<UserState, RootState> = {
    [UserStoreActions.GET_USER]: async({commit}): Promise<User> => {
        const result = await ProfileRepository.getProfile();
        const user = User.parseFromObject(result.data);
        commit(UserStoreMutations.STORE_USER, user);

        return user;
    },
    [UserStoreActions.GET_SCOPES]: async({commit, state}): Promise<User | null> => {
        const currentUser = state.currentUser;
        if(!currentUser) return null;

        const result = await AdminAccountsRepository.getUserProfileById(currentUser.id);
        const user = User.parseFromObject(result.data);
        commit(UserStoreMutations.STORE_USER, user);

        return user;
    }
};

const mutations: MutationTree<UserState> = {
    [UserStoreMutations.STORE_USER]: (state: UserState, user: User) => {
        state.currentUser = user;
    },
    [UserStoreMutations.CLEAR_STORE]: (state: UserState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialState);
    },
};

const getters: GetterTree<UserState, RootState> = {
    [UserStoreGetters.USER]: (state: UserState) => state.currentUser
};

const userStore: Module<UserState, RootState> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default userStore;

import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import GasStationRepository from "@/api/repositories/GasStationRepository";
import Gas from "@/models/GasMap";
import {GasState, RootState} from "@/misc/StoreStateInterface";

export const GAS_STORE_NAME = 'gas';

const initialState: GasState = {
    currentGas: undefined
};

const store: GasState = initialState;

export const enum GasStoreActions {
    UPDATE_MAP = 'UPDATE_MAP',
    LOAD_GAS = 'LOAD_GAS'
}

export const enum GasStoreGetters {
    GAS = 'GAS',
}

export const enum GasStoreMutations {
    STORE_GAS = 'STORE_GAS',
    CLEAR_STORE = 'CLEAR_STORE'
}

const actions: ActionTree<GasState, RootState> = {
    [GasStoreActions.LOAD_GAS]: async({commit}): Promise<Gas> => {
        // loads the gas station filter and stores them in the storage
        const gasRaw = await GasStationRepository.loadGasStations();
        const gas = Gas.parseFromObject(gasRaw.data);
        commit(GasStoreMutations.STORE_GAS, gas);
        return gas;
    },
    [GasStoreActions.UPDATE_MAP]: async({commit, state}, payload: {radius?: number, latitude?: number, longitude?: number}): Promise<void> => {
        // updates the filter
        await GasStationRepository.updateGas(payload);

        // updates the stored gas object to show the new location on the map etc.
        const updated = Gas.parseFromObject({
            ...state,
            radius: payload.radius,
            latitude: payload.latitude,
            longitude: payload.longitude
        });
        commit(GasStoreMutations.STORE_GAS, updated);
    },
};

const mutations: MutationTree<GasState> = {
    [GasStoreMutations.STORE_GAS]: (state: GasState, gas: Gas) => {
        state.currentGas = gas;
    },
    [GasStoreMutations.CLEAR_STORE]: (state: GasState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialState);
    }
};

const getters: GetterTree<GasState, RootState> = {
    [GasStoreGetters.GAS]: (state: GasState) => state.currentGas
};

const gasStore: Module<GasState, RootState> = {
    state: store,
    actions,
    mutations,
    getters,
};


export default gasStore;

import {AxiosResponse} from "axios";
import Parseable from '@/misc/Parseable';

export default class Scope extends Parseable {
	public name?: string;
	public description?: string;
	public scope! : string;
	public target?: string[];

	public static parseFromObject(object: Partial<Scope>): Scope {
		const scope = new Scope();

		Object.assign(scope, object);
		return scope;
	}

	public parseToObject(): Partial<Scope> {
		const tmp: any = {...this};
		return tmp;
	}
}






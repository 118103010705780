// @ts-ignore
import {DateTime} from "luxon";
import i18n from '@/i18n';

const FormattingPlugin = {
    install(Vue: any, options: any = {}) {
        // --- Date and time formats ---

        const formatDate = (date: DateTime) => {
            return date.toFormat(i18n.t('GENERAL.DATE_SHORT').toString());
        };
        Vue.prototype.$formatDate = formatDate;
        Vue.formatDate = formatDate;

        const formatDateTime = (date: DateTime) => {
            return date.toFormat(i18n.t('GENERAL.DATETIME_SHORT').toString());
        };
        Vue.prototype.$formatDateTime = formatDateTime;
        Vue.formatDateTime = formatDateTime;

        const formatTime = (date: DateTime) => {
            return date.toFormat(i18n.t('GENERAL.TIME_SHORT').toString());
        };
        Vue.prototype.$formatTime = formatTime;
        Vue.formatTime = formatTime;

        const dateTimeFromString = (value: string) => {
            // return DateTime.fromISO(value).setZone('Europe/Berlin');
            return DateTime.fromISO(value).toLocal();
        };
        Vue.prototype.$dateTimeFromString = dateTimeFromString;
        Vue.dateTimeFromString = dateTimeFromString;

        const formatMonthByString = (value: string) => {
            const date: DateTime = DateTime.fromISO(value);
            return date.toFormat(i18n.t('GENERAL.MONTH_FORMAT').toString());
        };
        Vue.prototype.$formatMonthByString = formatMonthByString;
        Vue.formatMonthByString = formatMonthByString;

        const formatDateFromString = (value: string) => {
            const date: DateTime = dateTimeFromString(value);
            return formatDate(date);
        };
        Vue.prototype.$formatDateFromString = formatDateFromString;
        Vue.formatDateFromString = formatDateFromString;

        const formatDateTimeFromString = (value: string) => {
            const date: DateTime = dateTimeFromString(value);
            return formatDateTime(date);
        };
        Vue.prototype.$formatDateTimeFromString = formatDateTimeFromString;
        Vue.formatDateTimeFromString = formatDateTimeFromString;

        const formatTimeFromString = (value: string) => {
            const date: DateTime = dateTimeFromString(value);
            return formatTime(date);
        };
        Vue.prototype.$formatTimeFromString = formatTimeFromString;
        Vue.formatTimeFromString = formatTimeFromString;


        // --- Currency formats ---

        const formatCurrency = (value: number) => {
            return currencyFormatter.format(value);
        };
        Vue.prototype.$formatCurrency = formatCurrency;
        Vue.formatCurrency = formatCurrency;

        // Passing 'undefined' for locale to use system locale
        const currencyFormatter = Intl.NumberFormat(i18n.locale, {
            style: 'currency',
            currency: 'EUR'
        });

        // --- percentage format ---

        const formatPercentage = (value: number) => {
            return percentageFormatter.format(value);
        };
        Vue.prototype.$formatPercentage = formatPercentage;
        Vue.formatPercentage = formatPercentage;

        const percentageFormatter = Intl.NumberFormat(undefined, {
            style: 'percent',
            maximumFractionDigits: 2,
            minimumFractionDigits: 1
        });

    }
};

export default FormattingPlugin;

import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import authStore, { AUTH_STORE_NAME } from './auth.store';
import disposalStore from "@/stores/disposal.store";
import userStore, {USER_STORE_NAME} from "@/stores/user.store";
import gasStore from "@/stores/gas.store";
import {RootState} from "@/misc/StoreStateInterface";
import sportStore from "@/stores/sport.store";

Vue.use(Vuex);

export const enum StoreActions {
    CLEAR_ALL_STORES = 'CLEAR_ALL_STORES'
}

// Persists auth and application module in local storage:
const vuexLocal = new VuexPersistence<RootState>({
    storage: window.localStorage,
    key: process.env.VUE_APP_VUEX_KEY,
    modules: [AUTH_STORE_NAME, USER_STORE_NAME]
});

const store: StoreOptions<RootState> = {
    modules: {
        auth: {
            namespaced: true,
            ...authStore,
        },
        disposal: {
            namespaced: true,
            ...disposalStore,
        },
        gas: {
            namespaced: true,
            ...gasStore,
        },
        user: {
            namespaced: true,
            ...userStore,
        },
        sport: {
            namespaced: true,
            ...sportStore,
        },
    },
    plugins: [vuexLocal.plugin],
    actions: {
        [StoreActions.CLEAR_ALL_STORES]: ({ commit }) => {
            Object.keys(store.modules!)
                .forEach((key: string) => commit(`${key}/CLEAR_STORE`));
        }
    }
};

export default new Vuex.Store<RootState>(store);

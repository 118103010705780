import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AxiosResponse} from "axios";

export default class ProfileRepository {
	public static async getProfile(): Promise<AxiosResponse> {
		return ENTITY_CLIENT.get('/api/profile');
	}
}




import {
  ROUTE_LOGIN,
  ROUTE_HOME_CARDS,
} from "@/router/routes";
import {Component, Vue} from "vue-property-decorator";
import store, {StoreActions} from "@/stores";

//@ts-ignore
import {version} from '../../../package';
import {namespace} from "vuex-class";
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/stores/auth.store";
import Scope from "@/models/Scope";
import {
  AdminNavigationService,
  adminNavigationServices,
  NavigationService,
  navigationServices
} from "@/misc/NavigationServices";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component
export default class NavigationBar extends Vue {
  @AuthStore.Getter(AuthStoreGetters.SCOPES)
  private scopes!: Scope[];

  private get appVersion() {
    return version;
  }

  private showNavigationDrawer: boolean = false;

  private get scopeBasedAdminServices(): AdminNavigationService[] {
    return adminNavigationServices.filter((n) => {
      if(!n.scope) return true;
      return this.$hasScope([n.scope!]);
    });
  }

  private get scopeBasedServices(): NavigationService[] {
    return navigationServices.filter((n) => this.$hasScope(n.scope));
  }

  private validateRoute(service: NavigationService): void {
    if(!service.routeTo) return this.onServiceClicked(service.route);
    window.open(service.routeTo, '_blank');
  }

  private onServiceClicked(route: string) {
    if(this.$route.name === route) return;
    this.$router.push({name: route});
  }

  private onAdminServiceClicked(service: AdminNavigationService): void {
    if(this.$route.name == service.route) return;
    this.$router.push({name: service.route});
  }

  public onClickHome() {
    if(this.$route.name === ROUTE_HOME_CARDS) return;
    this.$router.push({name: ROUTE_HOME_CARDS});
  }

  public logout() {
    store.dispatch(StoreActions.CLEAR_ALL_STORES);
    this.$router.push({name: ROUTE_LOGIN});
  }

  private get isHome(): boolean {
    return this.$route.name === ROUTE_HOME_CARDS;
  }

  private isActive(route: string): boolean {
    return this.$route.name?.includes(route) ?? false;
  }
}
